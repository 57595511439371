@import '../../styles/variables.scss';

.Header {
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  height: $header-height + 1px;
  background: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;

  .Header__LogoLink {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    .Header__Logo {
      width: 175px;
    }
  }

  .Header__Links {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .Header__MyPageLink {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 10px;
      right: 10px;
      display: block;
    }

    .Header__Notification {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 0;
      right: 0;
      border-radius: 50%;
      background: $red;
      color: $white;
      font-style: normal;
      font-size: 0.6rem;
      text-align: center;
      line-height: 1.4;
    }

    .Header__LinkButton {
      width: 90px;
      height: 32px;
      margin-right: 8px;
      color: $white;
      border-radius: 3px;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        color: $white;
      }

      &.PrimaryColor {
        background-color: $main-color;
      }

      &.SecondaryColor {
        background-color: $sub-color;
      }
    }
  }
}
