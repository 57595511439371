@import '../../../styles/variables.scss';

.selectedBookListItem {
  margin: 12px 0 24px;

  .title {
    color: $main-color;
    font-weight: bold;
  }

  .isbn {
    font-size: 0.9rem;
  }
}
