@import '../../styles/variables.scss';

.wrapper {
  margin: 0 -15px;
}

.categoryWrapper {
  margin: 8px 8px 0;
}

.parentCategory {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  background: $main-color;
  color: $white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title {
  margin: 0 7px;
  font-size: 1.4rem;
  flex-grow: 1;
}

.toggleIcon {
  width: 25px;
  height: 25px;
  padding: 4px;
  flex-basis: 28px;
}

.checkAll {
  margin: 0 8px;
  border-bottom: 1px solid $gray2;
}

.checkContent {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkAllText {
  font-size: 0.95rem;
  flex-grow: 1;
}

.icon {
  flex-basis: 40px;
}
