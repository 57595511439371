@import '../../../styles/variables.scss';

.button {
  width: 100%;
  padding: 12px 0;
  border: 0;
  font-size: 1.2rem;
  transition: all 0.3s 0s ease;
  cursor: pointer;

  @include isPC {
    padding: 8px 0;
    font-size: 1rem;
  }

  &.primaryColor {
    background: $main-color;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  &.defaultColor {
    border: 1px solid $gray2;
    background: $white;
    color: $gray4;

    &:hover,
    &:focus {
      background: $gray1;
      color: $gray4;
    }
  }

  &.isSmall {
    padding: 8px 0;
    font-size: 1rem;
  }

  &:disabled {
    background: $gray2 !important;
    color: $gray4 !important;
  }
}
