@import '../../styles/variables.scss';

.PriceConfirmation {
  margin: 32px 8px;
  padding: 16px;
  border-radius: 8px;
  background: $gray1;
  color: $gray4;

  .PriceConfirmation__Title {
    font-size: 1.2rem;
  }

  .PriceConfirmation__Price {
    margin: 16px 0 12px;
    color: $main-color;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .PriceConfirmation__Note {
    font-size: 1.1rem;
  }
}
