.BookCouponDetail {
  max-width: 720px;
  width: 100%;
  margin: 12px auto;

  dl {
    display: flex;
    flex-direction: column;
    width: 100%;

    dd {
      white-space: pre-line;
    }
  }
}
