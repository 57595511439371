@import '../../styles/variables.scss';

.link {
  position: relative;
  width: 100%;
  background: $white;
  color: $gray4;
  margin: 4px 0;
  padding: 16px;
  display: block;
}
