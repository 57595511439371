@import '../../styles/variables.scss';

.orderHeader {
  width: 100%;
  display: flex;
  background: $gray3;
  color: $white;

  &.isActive {
    background: $main-color;
  }

  .contentWrapper {
    padding: 4px;
    font-size: 0.8rem;
    flex-grow: 1;
  }

  .statusWrapper {
    padding: 8px;
    flex-basis: 112px;
    display: flex;
    align-items: center;

    .status {
      width: 100%;
      border-style: solid;
      border-width: 2px;
      background: $white;
      font-size: 0.9rem;
      text-align: center;
      color: $sub-color;
      border-color: $sub-color;

      &.isActive {
        color: $red;
        border-color: $red;
      }
    }
  }
}
