@import '../../styles/variables.scss';

.GiftForm {
  .GiftForm__Label {
    margin: 0;
    color: $main-color;
  }

  .GiftForm__MessageDescription {
    margin: 5px 0 8px;
    color: $gray4;
    line-height: 1.8;
  }

  .GiftForm__Price {
    margin: 0 -8px;
  }

  .GiftForm__Button {
    margin-bottom: 48px;
    text-align: center;
  }

  .GiftForm__CardRegistrationLink {
    margin: 8px 0;
    text-decoration: none;
    display: block;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
