@import '../../styles/variables.scss';

.profileMenuPage {
  margin: 0 -15px;
  background: $gray1;
  flex-grow: 1;

  .breadcrumbs {
    padding: 0 15px;
    background: $white;
  }

  .panelHeader {
    padding: 8px 15px;
    background: $white;
    color: inherit;
    display: block;
  }

  .userPhoto {
    width: 72px;
    height: 72px;
    float: left;
  }

  .userContent {
    height: 72px;
    margin-left: 72px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .userName {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
