@import '../../styles/variables.scss';

.title {
  font-size: 1.2rem;
  text-align: center;
}

.description {
  font-size: 0.95rem;
}

.karteOptions {
  margin: 16px 0;
}

.karteOption {
  padding: 8px;
  margin: 8px 0;
  border: 2px solid $sub-color;
  border-radius: 4px;
  color: $sub-color;
  text-align: center;
  transition: all 0.3s 0s ease;

  &.active,
  &:hover,
  &:active {
    background: $pale-blue;
  }
}
