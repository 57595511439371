@import '../../styles/variables.scss';

.template {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  padding-top: $header-height;
  flex-grow: 1;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
