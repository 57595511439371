.overlay {
  position: fixed;
  top: -50px;
  bottom: -50px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.body {
  position: absolute;
  top: 90px;
  left: 20px;
  right: 20px;
  bottom: 90px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 12px;
  outline: none;
  padding: 20px;
  transform: scale(1.2);
  transition: all 0.3s 0s ease;

  &.afterOpen {
    transform: scale(1);
  }

  &.beforeClose {
    transform: scale(1.2);
  }
}
