@import '../../styles/variables.scss';

.profilePanel {
  margin: 0 -15px;
  padding: 0 0 32px;

  .panelHeader {
    background: $white;
    padding: 8px 15px;
    color: inherit;
    display: block;

    &::after {
      border-color: $sub-color;
    }

    .userPhoto {
      width: 72px;
      float: left;
    }

    .userContent {
      height: 72px;
      margin-left: 72px;
      padding-left: 20px;
    }

    .userProfileWrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
    }

    .userProfile {
      width: 100%;
    }

    .userName {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .userLink {
      color: $main-color;
    }
  }
}
