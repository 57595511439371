@import '../../styles/variables.scss';

.ShuffleButton {
  position: fixed;
  right: 8px;
  bottom: 8px;
  width: 80px;
  height: 80px;
  border: 0;
  border-radius: 50%;
  background-color: $main-color;
  color: $white;
  font-size: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  .ShuffleButton__Icon {
    width: 32px;
    height: 32px;
  }
}
