@import '../../styles/variables.scss';

.mypage {
  margin: 0 -15px;
  padding: 0 15px;
  background: $gray1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .breadcrumbs {
    margin: 0 -15px;
    padding: 0 15px;
  }
}
