.PaymentAuthenticationFailure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;

  &__Button {
    max-width: 150px;
    width: 100%;
  }
}
