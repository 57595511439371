.progressBar {
  padding: 40px 0 64px;
}

.title {
  margin: 16px 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.body {
  padding-bottom: 40px;
}
