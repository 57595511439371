@import '../../../styles/variables.scss';

.listPageTemplate {
  flex-grow: 1;

  .title {
    margin: 32px 0 24px;
    font-size: 1.5rem;
  }

  .operations {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;

    .operationItem {
      width: 50%;
      display: flex;
      gap: 8px;

      & > * {
        width: 150px;
      }

      &:first-child {
        justify-content: start;
      }
      &:last-child {
        justify-content: end;
      }
    }
  }

  thead tr {
    background: $main-color;
    color: $white;

    th {
      text-align: center;
    }
  }

  tbody tr {
    cursor: pointer;

    &:nth-child(2n-1) {
      background: $gray1;
    }

    &:hover {
      background: $pale-blue;
    }
  }

  .pagination {
    margin: 16px 0;
  }
}
