@import '../../styles/variables.scss';

.staffLink {
  width: 100%;
  padding: 12px;
  background: $gray1;
  color: inherit;
  display: flex;

  &:hover,
  &:focus {
    color: inherit;
  }

  .staffPhoto {
    flex-basis: 76px;
    height: 76px;

    .photo {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .staffContentWrapper {
    display: flex;
    align-items: center;
    margin-left: 12px;
    flex-grow: 1;

    .staffContent {
      .bookStoreName {
        font-size: 0.8rem;
      }

      .staffName {
        font-size: 1.3rem;
      }
    }
  }
}
