@import 'src/styles/variables.scss';

.PaymentForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
  gap: 16px;

  .PaymentForm__StripeForm {
    min-height: 300px;

    @include isPC {
      min-height: 180px;
    }
  }
}
