@import '../../styles/variables.scss';

.GiftsPage {
  display: flex;
  flex-direction: column;
  background: $white;
  margin: 0 -15px;

  &__Title {
    margin: 32px auto;
    color: $main-color;
    font-weight: bold;
  }

  &__SubTitle {
    color: $main-color;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  &__FirstDescription {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  &__ThreeMethods {
    margin: 24px 0;
    padding: 48px 0 24px;
    background: $gray1;
  }

  &__ThreeIcons {
    margin: 32px 0;
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  &__MethodDescription {
    margin: 16px;
    padding: 24px;
    background: $white;
    border-radius: 24px;
    text-align: center;
  }

  &__MethodTitle {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
  }

  &__MethodImage {
    max-width: 300px;
    width: 100%;
    margin: 8px 0 16px;
  }

  &__MethodLink {
    display: block;
    margin: 24px 0 0;
    padding: 8px 0;
    border-radius: 8px;
    background: $main-color;
    color: $white;
    font-size: 18px;

    &:hover {
      color: $white;
    }
  }

  &__BackButton {
    display: block;
    margin: 8px 16px;
    padding: 8px 16px;
    border: 1px solid $gray3;
    border-radius: 8px;
    background: $white;
    color: $black;
    text-align: center;
  }
}
