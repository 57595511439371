@import '../../styles/variables.scss';

.stickyButton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.75);
}

.content {
  display: flex;
  width: 100%;
}

.button {
  padding: 12px 0;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.clearButton {
  background: $gray0;
  flex-basis: 100px;
}

.submit {
  flex-grow: 1;
  background: $sub-color;
  color: $white;
  margin-left: 12px;
}
