@import '../../styles/variables.scss';

.serviceDescription {
  margin: 0 -15px;
}

.bgCover {
  max-width: $max-sp-width;
  width: 100%;
  margin: 0 auto;
  display: block;
}
