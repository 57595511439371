@import '../../../styles/variables.scss';

.staffForm {
  max-width: 800px;

  .title {
    margin: 16px 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .editFile {
    margin: 32px 0;

    .editFilePreview {
      width: 100%;
      margin: 16px 0 32px;
      display: flex;

      .editFileItem {
        width: 200px;
        padding: 8px;
        background: $gray1;

        .removePhotoButton {
          margin-top: 8px;
        }

        .editFileImage {
          width: 184px;
          height: 184px;
          object-fit: cover;
        }
      }
    }

    .fileButton {
      max-width: 400px;
    }
  }

  .categoryForm {
    margin: 32px 0 0;
  }

  .recommendationDescription {
    font-size: 0.8rem;
  }

  .buttons {
    text-align: center;

    .button {
      width: 180px;
      margin: 40px 20px;
      display: inline-block;
    }
  }
}
