@import '../../../styles/variables.scss';

.checkbox {
  width: 100%;
  display: flex;
  margin: 8px 0;
  cursor: pointer;

  .input {
    position: absolute;
    left: -9999px;
  }

  .checkWrapper {
    flex-basis: 30px;

    .check {
      padding: 7px 5px;
      border: 1px solid $gray3;
      background: $white;
      transition: all 0.15s 0s ease;

      &.isChecked {
        border: 1px solid $white;
        border-radius: 4px;
        background: $sub-color;
        color: $white;
      }
    }
  }

  .label {
    margin-left: 8px;
    color: $gray4;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}
