@import '../../styles/variables.scss';

.RegistrationButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 8px;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px;

  &__Link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-grow: 1;
    padding: 8px;
    border-radius: 4px;
    background: $sub-color;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}
