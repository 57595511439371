@import '../../styles//variables.scss';

.GiftDescription {
  text-align: center;

  p {
    margin-bottom: 4px;
  }

  &__Title {
    margin: 24px 0 16px;
    color: $main-color;
    font-size: 24px;
    font-weight: bold;
  }

  &__Note {
    font-weight: bold;
  }
}
