@import '../../styles/variables.scss';

.expiresResetTokenPage {
  .description {
    margin: 32px 0;
  }

  .button {
    max-width: 500px;
    margin: 20px auto;
  }
}
