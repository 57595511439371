$main-color: #2c3f86;
$sub-color: #5cb4ff;
$highlight-color: #ff715f;
$pale-blue: #e5f9ff;
$white: #ffffff;
$black: #212121;
$gray0: #fafafc;
$gray1: #f5f5f5;
$gray2: #cccccc;
$gray3: #aaaaaa;
$gray4: #575757;
$pale-red: #ffe2df;
$red: #ff715f;
$dark-red: #cc5a4c;

$max-sp-width: 414px;
$header-height: 60px;

@mixin isSP {
  @media screen and (max-width: $max-sp-width) {
    @content;
  }
}

@mixin isPC {
  @media screen and (min-width: $max-sp-width + 1px) {
    @content;
  }
}
