@import '../../../styles/variables.scss';

$admin-header-height: 68px;

.adminTemplate {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $admin-header-height;
    z-index: 1;
  }

  .body {
    margin-top: $admin-header-height;
    padding-bottom: 40px;
    flex-grow: 1;
  }
}
