@import '../../styles/variables.scss';

.orderListPage {
  margin: 0 -15px;
  padding: 15px;
  background: $white;
  flex-grow: 1;

  .title {
    margin: 8px 0 20px;
    padding: 8px 0;
    color: $main-color;
    border-bottom: 1px solid $gray4;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .notificationCount {
    position: relative;
    top: -2px;
    width: 20px;
    height: 20px;
    margin: 0 8px;
    border-radius: 50%;
    background: $red;
    color: $white;
    font-size: 0.8rem;
    text-align: center;
    line-height: 20px;
    display: inline-block;
  }
}
