@import '../../styles/variables.scss';

.resetPasswordEmailSentPage {
  .title {
    margin: 16px 0;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .description p {
    margin: 4px 0;
  }

  .email {
    max-width: 500px;
    margin: 20px 0;
    padding: 8px 0;
    border: 2px solid $main-color;
    border-radius: 4px;
    color: $main-color;
    font-weight: bold;
    text-align: center;
  }

  .button {
    width: 180px;
    margin: 40px auto;
  }
}
