@import '../../styles/variables.scss';

.staffList {
  margin: 0 -15px;
  padding: 15px;
  background: $gray1;
  flex-grow: 1;
}

.pagination {
  margin: -30px -15px 0;
  padding: 15px;
  background: $gray1;
}
