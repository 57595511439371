.PublicationRecord {
  .PublicationRecord__Title {
    font-size: 20px;
    margin: 16px 0;
    text-align: center;
  }

  .PublicationRecord__Scrollable {
    display: flex;
    width: 100vw;
    overflow: hidden;

    .PublicationRecord__LogoList {
      margin: 0;
      padding: 0;
      display: flex;

      &:first-child {
        animation: loop1 20s -10s linear infinite;
      }

      &:last-child {
        animation: loop2 20s -20s linear infinite;
      }

      .PublicationRecord__LogoListItem {
        width: 120px;
        height: 120px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        list-style: none;

        .PublicationRecord__Logo {
          max-width: 100%;
        }
      }
    }
  }
}

@keyframes loop1 {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-200%);
  }
}
