@import '../../styles/variables.scss';

.GiftRequestForm {
  .GiftRequestForm__Title {
    margin: 32px 0;
    font-size: 24px;
    font-weight: normal;
  }

  .GiftRequestForm__Description {
    margin: 32px 0;
    color: $gray4;

    ul {
      margin: 8px 0;
    }

    p {
      margin: 4px 0;
    }
  }

  .GiftRequestForm__Label {
    margin: 0;
    color: $main-color;
  }

  .GiftRequestForm__MessageDescription {
    margin: 5px 0 8px;
    color: $gray4;
    line-height: 1.8;
  }
}
