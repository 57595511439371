@import '../../styles/variables.scss';

.selectedBookListItem {
  position: relative;
  min-height: 48px;
  margin: 8px 0;
  padding: 12px;
  border: 1px solid $gray3;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.nonActive {
    border-color: $red;
    color: $red;
  }

  .rejectedReason {
    color: $red;
    font-size: 0.8rem;
  }

  .content {
    margin: 0 -12px;
    padding: 0 12px;
    flex-grow: 1;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  }

  .operation {
    margin-top: 4px;
    text-align: right;

    .button {
      width: 60px;
      padding: 4px;
      border: 0;
      background: $gray1;
      color: $main-color;
      font-size: 0.9rem;
      text-align: center;

      &.isActive {
        background: $sub-color;
        color: $white;
      }

      & + .button {
        margin-left: 8px;
      }
    }
  }

  .rejection {
    margin-top: 12px;
    border-top: 1px solid $gray3;
    padding: 4px 0;
  }
}
