@import '../../styles/variables.scss';

.wrapper {
  margin: 0 8px;
  border-bottom: 1px solid $gray2;
}

.content {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text {
  font-size: 0.95rem;
  flex-grow: 1;
}

.icon {
  flex-basis: 40px;
}
