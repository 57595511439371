@import '../../styles/variables.scss';

.flashMessage {
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
  transition: all 0.2s 0s ease-in-out;

  &.active {
    top: 0;
  }
}

.message {
  margin: 12px;
  padding: 12px 16px;
  background: $gray4;
  color: $white;
  line-height: 1.5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
