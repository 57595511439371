@import '../../styles/variables.scss';

.ToggleHeader {
  position: relative;
  margin: 8px 0;
  padding: 8px 0;
  padding-left: 32px;
  color: $gray4;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    margin: auto;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-right: 2px solid $sub-color;
    border-top: 2px solid $sub-color;
    display: block;
    transition: all 0.3s 0s ease;
  }

  &.isOpen {
    &::before {
      transform: rotate(135deg);
    }
  }
}
