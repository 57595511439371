.or {
  width: 100%;
  height: 60px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 76px;
  background: #fff;
  padding: 12px 15px;
}
