@import '../../styles/variables.scss';

.firstview {
  margin: 40px -15px 15px;
  text-align: center;
}

.firstviewImage {
  width: 100%;
  max-width: $max-sp-width;
}
