@import '../../styles/variables.scss';

.breadcrumbList {
  padding: 8px 0;
  background: $white;
}

.breadcrumbListItem {
  font-size: 0.9rem;
  display: inline-block;

  & + .breadcrumbListItem::before {
    margin: 0 4px;
    content: '>';
    display: inline-block;
  }
}

.breadcrumbListItemLink {
  color: $main-color;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
