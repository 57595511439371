@import '../../styles/variables.scss';

.StaffListItem {
  padding: 15px;
  margin: 15px 0;
  background: $white;
  color: inherit;
  display: block;

  &:hover,
  &:focus {
    color: inherit;
  }

  .StaffListItem__StaffInfo::after {
    content: '';
    display: block;
    clear: both;
  }

  .StaffListItem__StaffPhoto {
    width: 125px;
    float: left;
  }

  .StaffListItem__Photo {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border-radius: 8px;
  }

  .StaffListItem__StaffProfile {
    padding-left: 15px;
    margin-left: 125px;
  }

  .StaffListItem__BookStoreName {
    font-size: 1.1rem;
  }

  .StaffListItem__StaffName {
    font-size: 1.4rem;
  }

  .StaffListItem__StaffProfileText {
    margin: 12px 0;
    font-size: 0.95rem;
    color: $gray4;
  }

  .StaffListItem__CategoryList {
    margin: 4px 0;
  }

  .StaffListItem__Button {
    margin-top: 12px;
  }
}
