.shipmentForm {
  .buttons {
    text-align: center;

    .button {
      width: 180px;
      margin: 40px 20px;
      display: inline-block;
    }
  }
}
