@import '../../styles/variables.scss';

.staffPhoto {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s 0s ease;

  &.active {
    opacity: 1;
  }
}
