@import '../../styles/variables.scss';

.label {
  margin: 5px 0;
  color: $main-color;
}

.value {
  color: $gray4;
}

.button {
  margin: 0 0 36px;
}
