@import '../../styles/variables.scss';

.Footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
  color: $gray4;
  font-size: 0.8rem;

  .Footer__Content {
    display: flex;
    gap: 4px;
    justify-content: center;
  }

  .Footer__Link {
    color: $gray4;
  }

  .Footer__Logos {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  .Footer__Logo {
    width: 200px;
  }

  .Footer__XLogo {
    width: 48px;
  }

  .Footer__Copyright {
    color: $main-color;
    text-align: center;
  }
}
