.selectedBooksForm {
  max-width: 800px;

  .fields {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .addButtonWrapper {
    margin: 40px 0 20px;

    .addButton {
      width: 180px;
      display: inline-block;
    }
  }

  .error {
    margin: 24px 12px 0 0;
  }

  .buttons {
    text-align: center;

    .button {
      width: 180px;
      margin: 40px 20px;
      display: inline-block;
    }
  }
}
