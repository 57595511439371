@import '../../styles/variables.scss';

.FirstView {
  padding-top: 20px;
  margin: 0 -15px;
  text-align: center;

  &__Image {
    width: 100%;
    max-width: $max-sp-width;
  }

  &__Link {
    display: block;
    margin: 24px auto;
  }
}
