@import './variables.scss';

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans CJK JP Regular'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format('opentype');
}

html,
body {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  font-family: 'Noto Sans JP';

  @include isPC {
    font-size: 14px;
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

hr {
  border-color: $gray2;
}

.linkNavigator {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    width: 12px;
    height: 12px;
    margin: auto;
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: $gray3;
    display: block;
    transform: rotate(45deg);
  }
}
