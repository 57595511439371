@import '../../styles/variables.scss';

.toggleBox {
  width: 100%;
  height: 50px;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toggleText {
  position: relative;
  color: $main-color;

  &::after {
    content: '';
    position: absolute;
    top: 8px;
    right: -20px;
    width: 10px;
    height: 10px;
    border: solid $main-color;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
    display: block;
  }

  &.isOpen::after {
    transform: rotate(135deg);
  }
}
