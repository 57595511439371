@import '../../styles/variables.scss';

.FlowDescription {
  margin: 0 -15px;
  padding: 24px 0;
  background-color: $gray1;

  .FlowDescription__Title {
    margin-bottom: 24px;
    color: $main-color;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  .FlowDescription__Description {
    color: $main-color;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
  }

  .FlowDescription__FlowList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }

  .FlowDescription__ListItem {
    padding: 16px;
    background-color: $white;
    border-radius: 24px;
    overflow: hidden;
  }

  .FlowDescription__ListItemIndex {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    border-radius: 50%;
    background: $sub-color;
    color: $white;
    text-align: center;
    line-height: 40px;
    display: inline-block;
  }

  .FlowDescription__ItemTitle {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

  .FlowDescription__Image {
    width: 100%;
    height: 160px;
    object-fit: contain;
    margin: 20px 0;
  }
}
