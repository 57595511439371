@import '../../styles/variables.scss';

.panelHeader {
  margin: 0 -15px;
  padding: 20px 15px;
  border-bottom: 1px solid $gray3;
  background: $white;

  .userPhoto {
    width: 120px;
    height: 120px;
    margin: 12px auto;
    border-radius: 50%;
    background: $gray1;
  }

  .fileChangeButton {
    width: 120px;
    margin: 0 auto;
  }
}
