@import '../../styles/variables.scss';

.itemList {
  width: 180px;
  height: 5px;
  margin: 0 auto;
  border-radius: 2px;
  background: $gray2;
  display: flex;
}

.item {
  flex-grow: 1;

  &.active {
    background: $sub-color;
  }
}
