@import '../../../styles/variables.scss';

.adminHeader {
  background: $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  .contentWrapper {
    width: 100%;
    height: 100%;
    display: flex;

    .logoWrapper {
      padding: 8px;
      display: flex;
      align-items: center;
      flex-basis: 170px;

      .logo {
        height: 48px;
      }
    }

    .menuList {
      display: flex;
      flex-grow: 1;

      .menuListItem {
        width: 120px;
        background: $white;
        color: $sub-color;
        display: flex;
        align-items: center;
        transition: all 0.5s 0s ease;

        &:hover,
        &:focus {
          background: $gray1;
          color: $main-color;
        }

        .linkText {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
