.categories {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
}

.categoryItem {
  width: 20%;
  padding: 0 3px;
}

.categoryIcon {
  width: 100%;
}
