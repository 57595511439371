.SimpleDocument {
  font-size: 0.8rem;

  h2 {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 24px 0 8px;
  }

  h3 {
    font-size: 0.8rem;
    font-weight: bold;
  }

  ul {
    padding-left: 16px;
  }

  ol {
    padding-left: 0;
    counter-reset: item;
    list-style-type: none;

    & > li {
      margin: 4px 0;
      text-indent: -0.8rem;
      padding-left: 0.8rem;

      &::before {
        counter-increment: item;
        content: counter(item) '. ';
      }

      h3 {
        margin: 0;
        padding-left: 0.3rem;
      }
    }

    ol {
      padding-left: 1rem;
    }

    &.SimpleDocument__BracketStyle {
      li {
        margin: 16px 0 0;
        text-indent: 0;
        padding-left: 0;

        &::before {
          content: '(' counter(item) ')';
        }
      }
    }
  }

  p {
    margin: 4px 0;
  }
}
