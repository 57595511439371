@import '../../styles/variables.scss';

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  text-align: center;
}

.logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 150px;
  width: 300px;
  margin: auto;
}

.text {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  margin: auto;
  height: 1rem;
  animation: flash 1s linear infinite;
}

@keyframes flash {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
