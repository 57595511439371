.resetPasswordPage {
  .title {
    margin: 16px 0;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .description {
    margin: 32px 0;
  }
}
