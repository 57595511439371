@import '../../../styles/variables.scss';

.select {
  position: relative;
  background: $gray1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 1px;
    top: 5px;
    right: 5px;
    border-style: solid;
    border-top: 10px $main-color;
    border-right: 10px $main-color;
    border-bottom: 10px transparent;
    border-left: 10px transparent;
  }
}

.label {
  color: $main-color;
}

.required {
  color: $red;
}
