@import '../../styles/variables.scss';

.selectedBookList {
  padding: 8px;
  background: $gray1;

  .title {
    position: relative;
    color: $gray4;
    margin-bottom: 8px;
    transition: all 0.3s 0s ease;

    &::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 8px;
      width: 12px;
      height: 12px;
      border-style: solid;
      border-color: $gray3;
      border-width: 2px 0 0 2px;
      display: block;
      transform: rotate(45deg);
    }

    &.isOpen::after {
      transform: rotate(225deg);
    }
  }
}
