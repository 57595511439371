@import '../../styles/variables.scss';

.wrapper {
  position: relative;
  padding: 16px 16px 32px;
  margin-bottom: 36px;
  background: $gray1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 60px;
    right: 32px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: $gray3;
    transform: rotate(45deg);
  }

  .select {
    border: 2px solid $gray3;
    background: $white;
    color: $gray3;

    -moz-appearance: none;
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  &.active {
    .select {
      border-color: $sub-color;
      color: $sub-color;
    }

    &::after {
      border-color: $sub-color;
    }
  }
}
