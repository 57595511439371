@import '../../staffs/CategorySelector.module.scss';

.button {
  width: 100%;
  padding: 8px 0;
  border: 1px solid $sub-color;
  border-radius: 16px;
  background: $white;
  color: $sub-color;
  font-size: 0.9rem;
  text-align: center;
  transition: all 0.3s 0s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $sub-color;
    color: $white;
  }
}
