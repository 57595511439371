@import '../../styles/variables.scss';

.karteTemplateDetailPage {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .contentWrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;

    .content {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      text-align: center;

      .noItemDescription {
        color: $gray4;
      }

      .button {
        margin: 16px 0;
      }
    }
  }
}
