@import '../../styles/variables.scss';

.StaffIntroduction {
  margin: 0 -15px;
  text-align: center;

  &__Title {
    max-width: $max-sp-width;
    height: 160px;
    padding: 20px 0 0;
    margin: 0 auto;
    font-size: 1.1rem;
    line-height: 2.5rem;
  }

  &__StaffList {
    max-width: $max-sp-width;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
