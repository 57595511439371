@import '../../styles/variables.scss';

.karteTemplateListItem {
  margin: 8px 0;
  padding: 8px 0;
  text-align: center;
  background: $white;
  color: $sub-color;
  border: 2px solid $sub-color;
  border-radius: 4px;
  display: block;
  transition: all 0.3s 0s ease;

  &:hover,
  &:focus,
  &.active {
    color: $sub-color;
    background: $pale-blue;
  }

  &.disabled {
    background: $gray2;
    color: $gray4;
    border-width: 1px;
    border-color: $gray4;
  }
}
