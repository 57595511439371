.PostCardGiftForm {
  display: flex;
  flex-direction: column;

  &__Price {
    margin: 0 -8px;
  }

  &__Button {
    margin: 0 0 48px;
    text-align: center;
  }

  &__CardRegistrationLink {
    margin: 8px 0;
    text-decoration: none;
    display: block;
  }
}
