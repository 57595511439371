@import '../../styles/variables.scss';

.karteLink {
  padding: 12px 8px 4px;
  background: $gray1;

  .label {
    margin-bottom: 8px;
    color: $gray4;
  }

  .link {
    margin: 8px 0;
    padding: 12px;
    border: 1px solid $gray3;
    border-radius: 4px;
    background: $white;
    color: $gray4;
    font-size: 0.9rem;
    display: block;

    &:hover,
    &:focus {
      color: $gray4;
    }
  }
}
