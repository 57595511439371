@import '../../styles/variables.scss';

.actionMessage {
  position: relative;
  margin: 16px 0;
  padding: 12px 16px;
  border: 2px solid $red;
  color: $main-color;
  font-size: 0.9rem;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: -12px;
    left: 0;
    right: 0;
    margin: auto;
    border: $red solid;
    border-width: 0 2px 2px 0;
    background: $white;
    display: block;
    transform: rotate(45deg);
  }
}
