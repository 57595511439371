@import '../../styles/variables.scss';

.PriceDescription {
  margin: 0 -15px;
  padding: 0 16px;
  background-color: $gray1;

  .PriceDescription__Content {
    padding: 40px 20px;
    border-radius: 24px;
    background: $main-color;
    color: $white;
    text-align: center;
  }

  .title {
    font-size: 2rem;
    margin: 10px 0 30px;
  }

  .description {
    margin: 10px 0 40px;
    font-size: 1.4rem;
  }

  .priceArea {
    padding: 30px 0;
    border-radius: 15px;
    background: $white;
    color: #000;
    font-weight: bold;

    .price {
      font-size: 1rem;
    }

    .yen {
      color: $sub-color;
      font-size: 1rem;
    }

    strong {
      font-size: 2rem;
      color: $sub-color;
    }

    .additionalFee {
      font-size: 1rem;
    }
  }
}
