@import '../../styles/variables.scss';

.GiftDescription {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 -15px;
  background-image: url('../../assets/welcome/bg-dot.svg');

  .GiftDescription__TopImage {
    height: 80px;
    background-image: url('../../assets/welcome/bg-book-shadow.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  h2 {
    color: $main-color;
    font-weight: bold;
    text-align: center;
  }

  .GiftDescription__Summary {
    color: $main-color;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
  }

  .GiftDescription__Description {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }

  .GiftDescription__SceneTitle {
    max-width: 280px;
    margin: 0 auto;
  }

  .GiftDescription__SceneList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px;
  }

  .GiftDescription__SceneListItem {
    display: flex;
    padding: 12px;
    border-radius: 24px;
    background-color: $pale-blue;

    & > * {
      width: 50%;
    }
  }

  .GiftDescription__SceneText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .GiftDescription__SceneImage {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}
