@import '../../../styles/variables.scss';

.stickyButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: $gray4;

  .button {
    padding: 8px;
    background: $sub-color;
    color: $white;
    font-size: 1.2rem;
    text-align: center;
    display: block;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  .disabled {
    background: $gray2;
    color: $gray4;
  }
}
