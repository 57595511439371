@import '../../../styles/variables.scss';

.label {
  margin: 5px 0;
  color: $main-color;
}

.input {
  background-color: $white;
}

.gray {
  background-color: $gray1;
}

.required {
  color: $red;
}
