@import '../../styles/variables.scss';

.profileDetail {
  .panelHeader {
    margin: 0 -15px;
    padding: 20px 15px;
    text-align: center;
    border-bottom: 1px solid $gray3;
  }

  .userPhoto {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .userName {
    margin: 16px 0;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .editLink {
    width: 72px;
    padding: 4px 0;
    border: 1px solid $sub-color;
    border-radius: 16px;
    color: $sub-color;
    font-size: 0.9rem;
    display: inline-block;
    transition: all 0.3s 0s ease;

    &:hover,
    &:focus {
      background: $sub-color;
      color: $white;
    }
  }

  .profileContent {
    padding: 20px 0;

    .label {
      margin: 24px 0 4px;
      color: $main-color;
    }

    .value {
      margin: 0 0 8px;
      color: $gray4;
    }
  }
}
