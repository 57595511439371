.adminUserDetail {
  .table {
    margin: 24px 0;
    table-layout: fixed;

    .col1 {
      width: 150px;
    }

    th {
      text-align: center;
      vertical-align: middle;
    }
  }
}
