@import '../../../styles/variables.scss';

.staffCategoryItem {
  width: 80px;
  margin: 0 0 16px;
  text-align: center;

  .icon {
    width: 60px;
    height: 60px;
    object-fit: cover;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s 0s ease;

    &.isSelected {
      opacity: 1;
    }
  }

  .label {
    margin: 4px 0;
    font-size: 0.8rem;
  }
}
