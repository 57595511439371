.authenticatedPage {
  padding-bottom: 40px;

  .title {
    margin: 48px 0 32px;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .loginForm {
    margin: 16px 0 64px;
  }
}
