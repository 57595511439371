.staffThumbnail {
  margin: 10px 0;
  text-align: center;
  color: inherit;
}

.staffIcon {
  max-width: 100px;
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.bookStoreName {
  max-width: 100px;
  width: 100%;
  margin: 0 auto;
  font-size: 0.8rem;
  text-align: left;
}
