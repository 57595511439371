@import '../../styles/variables.scss';

.staffFilter {
  margin: 0 -15px;
  padding: 15px;
  background: $gray1;
}

.title {
  margin: 16px 0 10px;
  font-size: 1.5rem;
  font-weight: bold;
}

.filterBox {
  padding: 1px 15px 15px;
  background: $white;
}

.label {
  margin: 15px 0 5px;
  color: $main-color;
}

.categoryLink {
  position: relative;
  padding: 0.375rem 0.75rem;
  margin: 8px 0;
  border: 1px solid $gray2;
  color: $gray3;
  text-align: center;
  display: block;

  &:hover,
  &:focus {
    color: $gray3;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    right: 15px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: $gray3;
    transform: rotate(45deg);
  }

  &.active {
    border-color: $sub-color;
    border-width: 2px;
    color: $sub-color;
    font-weight: bold;

    &::after {
      border-color: $sub-color;
    }
  }
}

.submit {
  margin: 16px 0;
}
