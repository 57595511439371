@import '../../styles/variables.scss';

.GiftBottomNavigation {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0 48px;

  &__Button {
    background: $white;
    padding: 8px 0;
    border: 1px solid $gray2;
    border-radius: 8px;
    color: $black;
    text-align: center;
  }
}
