@import 'src/styles/variables.scss';

.PaymentDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 12px 0;

  p {
    margin: 0;
  }

  .PaymentDetail__Title {
    font-size: 1.4rem;
  }

  .PaymentDetail__CardInfo {
    display: flex;
    gap: 12px;
  }

  .PaymentDetail__NoRegistration {
    color: $gray4;
  }
}
