@import '../../styles/variables.scss';

.staffPhotoList {
  position: relative;
  max-width: $max-sp-width;
  height: 300px;
  margin: 0 auto;
}

.indicatorList {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  width: 8px;
  height: 8px;
  margin: 0 8px;
  border-radius: 50%;
  background: $gray3;

  &.active {
    background: $sub-color;
  }
}
