@import '../../styles/variables.scss';

.StaffDetail {
  .StaffDetail__BookStoreName {
    margin: 0 15px;
    font-size: 1.1rem;
  }

  .StaffDetail__StaffName {
    margin: 0 15px;
    padding: 0 0 4px;
    font-size: 1.6rem;
    font-weight: 600;
    border-bottom: 3px solid $main-color;
  }

  .StaffDetail__Slogan {
    max-width: $max-sp-width + 15px;
    padding: 0 15px;
    margin: 20px auto;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .StaffDetail__Categories {
    max-width: 320px;
    margin: 20px auto;
  }

  .StaffDetail__StaffProfile {
    padding: 15px;
  }

  .StaffDetail__StaffProfileTitle {
    font-size: 1.15rem;
    color: $main-color;
    font-weight: bold;
  }
}
