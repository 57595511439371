@import '../../styles/variables.scss';

.pagination {
  .button {
    width: 80px;
    padding: 8px;
    text-align: center;
    background: $white;
    color: $gray4;
    border: 1px solid $gray2;
    cursor: pointer;
  }

  .goNext {
    float: right;
  }

  .goPrev {
    float: left;
  }
}
