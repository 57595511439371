@import '../../styles/variables.scss';

.ThanksPage {
  .ThanksPage__Title {
    margin: 32px 0;
    font-size: 24px;
    font-weight: normal;
  }

  .ThanksPage__Description {
    margin: 32px 0;
    color: $gray4;
  }

  .ThanksPage__ShipmentDescription {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 24px 0 48px;

    p {
      margin: 0;
    }
  }
}
