@import '../../styles/variables.scss';

.notFoundPage {
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;

  .content {
    max-width: 300px;
    height: 300px;
    margin: 0 auto;
  }

  .title {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .link {
    width: 200px;
    padding: 16px 0;
    margin: 36px auto 20px;
    border: 1px solid $sub-color;
    color: $sub-color;
    font-size: 1.1rem;
    font-weight: bold;
    display: block;
    transition: all 0.3s 0s ease;

    &:hover,
    &:focus {
      background: $sub-color;
      color: $white;
    }
  }
}
