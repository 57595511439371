.ByHandsGiftForm {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__Description {
    text-align: center;
    margin: 48px 0 24px;
  }

  &__AddressConfirmation {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 24px auto;
    gap: 16px;
  }

  &__Address {
    margin: 0;
  }

  &__ChangeAddressLink {
    display: block;
    text-align: center;
  }

  &__Price {
    margin: 0 -8px;
  }

  &__Button {
    margin: 0 0 48px;
    text-align: center;
  }

  &__CardRegistrationLink {
    margin: 8px 0;
    text-decoration: none;
    display: block;
  }
}
