@import '../../../styles/variables.scss';

.rejectedBook {
  .content {
    width: 100%;
    display: flex;
    align-items: center;

    .removeButtonWrapper {
      flex-basis: 40px;

      .removeButton {
        width: 20px;
        height: 20px;
        margin: 10px;
        cursor: pointer;
      }
    }

    .bookContent {
      flex-grow: 1;

      .basicInfo {
        width: 100%;
        display: flex;
        align-items: center;

        .title {
          flex: 1;
          font-size: 1.2rem;
        }

        .isbn {
          flex: 1;
        }
      }
    }
  }

  .reason {
    color: $red;
    font-size: 0.9rem;
  }
}
