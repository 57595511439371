@import '../../../styles/variables.scss';

.editOrderPage {
  .title {
    margin: 32px 0 24px;
    font-size: 1.5rem;
  }

  .detail {
    dt {
      color: $main-color;
    }
  }

  .subtitle {
    font-size: 1.5rem;
    margin: 24px 0;
  }

  .buttons {
    margin: 24px 0;
    text-align: center;

    .button {
      width: 180px;
      margin: 24px 0;
      display: inline-block;
    }
  }
}
