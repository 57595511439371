@import '../../../styles/variables.scss';

.selectedBookField {
  .fields {
    width: 100%;
    display: flex;

    .removeButtonWrapper {
      flex-basis: 40px;

      .removeButton {
        width: 20px;
        height: 20px;
        margin: 38px 10px 0;
        cursor: pointer;
      }
    }

    .field {
      flex-grow: 1;
      padding-right: 12px;
      display: inline-block;
    }
  }
}
