@import '../../styles/variables.scss';

.karteDetail {
  dt {
    margin: 8px 0;
    color: $main-color;
  }

  dd {
    margin-bottom: 32px;
    white-space: pre-line;
  }
}
