@import '../../styles/variables.scss';

.OrderThanksPage {
  max-width: 600px;
  margin: 32px auto;

  .OrderThanksPage__Image {
    width: 100%;
  }

  .OrderThanksPage__Description {
    margin: 32px 0;
    color: $gray4;
  }

  .OrderThanksPage__Email {
    margin: 32px 0;
    color: $main-color;
  }

  .OrderThanksPage__EmailDescription {
    font-size: 14px;
  }

  .OrderThanksPage__Panel {
    margin: 32px 0;
    padding: 16px;
    border-radius: 8px;
    background: $gray1;

    .OrderThanksPage__PanelTitle {
      margin: 12px 0;
      color: $main-color;
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }

    .OrderThanksPage__PanelDescription {
      margin: 12px 0;
      color: $gray4;
    }
  }
}
