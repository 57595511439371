.staffCategoryForm {
  .parentCategoryItem {
    .subtitle {
      margin: 16px 0;
      font-size: 1.2rem;
    }
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
  }
}
