@import '../../styles/variables.scss';

.form {
  padding: 15px;
  margin-bottom: 8px;
  background: $gray1;
}

.resetPasswordLink {
  margin: 15px 0 5px;
  color: $main-color;
  text-decoration: underline;
  text-align: center;
  display: block;
}

.error {
  margin: 8px 0;
}

.button {
  margin: 20px 0;
}
